<template>
  <div class="document-content">
    <div class="header">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Régie</span>
          </div>

          <SelectComponent
            :options="computedgetAllRegie"
            :value="filterRegie"
            label="name"
            champName="régie"
            filterName="filterRegie"
            :change="handleChange"
            :track-by="'name'"
            placeholder="Rechercher"
            :searchable="true"
            :showLabels="false"
            classToUse="select-vue-component-style bg-select-vue w-1"
          />
        </div>

        <div v-if="getChargementDocRegie" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>

    <div class="body-box-rapport">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style tableDocRegie  table-header-regie"
          :items="getRegieDocFiltred"
          :fields="fields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          empty-text="Il n'y a aucun enregistrement à afficher"
          :tbody-tr-class="rowClass"
        >
          <template v-slot:cell(detail)="data">
            <div
              class="checkbox "
              :class="{ checked: data.item.details == true }"
              @click="handleChangeDetails(data.item)"
            >
              <font-awesome-icon class="checkbox_icon" icon="check" />
            </div>
          </template>
          <template v-slot:cell(regie_name)="data">
            {{ data.item.name | FilterValue }}
          </template>
          <template v-slot:cell(email)="data">
            {{ data.item.email | FilterValue }}
          </template>
          <template v-slot:cell(adress)="data">
            {{ data.item.adress | FilterValue }}
          </template>
          <template v-slot:cell(code_postal)="data">
            {{ data.item.code_postal | FilterValue }}
          </template>
          <template v-slot:cell(phone)="data">
            {{ data.item.phone | FilterValue }}
          </template>
          <template v-slot:cell(ville)="data">
            {{ data.item.ville | FilterValue }}
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowDocRegie"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
        <b-modal
          id="regieInfo"
          ref="regieInfo"
          :hide-footer="true"
          @hidden="resetModal"
          :hide-header="true"
          modal-class="cutsom-modal-bootstrap"
        >
          <div class="hader">
            <div class="title">
              <div class="label">Régie</div>
              <div class="value" v-if="getRegiechecked && getRegiechecked.name">
                {{ getRegiechecked.name }}
              </div>
            </div>
            <div class="iconClose" @click.prevent="hideModal('regieInfo')">
              <font-awesome-icon icon="times" />
            </div>
          </div>

          <div v-if="getLoadingModelRegie" class="three-dots-loading ">
            Chargement en cours
          </div>

          <tabs
            :list_tab="listTab"
            @tabsChange="tabsChange"
            :active_tabs="tabsActive"
            :occ="getAllFiles"
          />
          <div
            class="position-box"
            v-if="getRegiechecked && tabsActive === 'Fichiers'"
          >
            <file-component
              :editable="cantExport(this.$options.name)"
              :supprimer="cantDelete(this.$options.name)"
              :RegieFiles="getRegiechecked"
              :options="options"
              :deleted="false"
              :download="true"
            />
          </div>
          <div
            class="position-box"
            v-if="getRegiechecked && tabsActive === 'Coordonnées'"
          >
            <data-component
              :RegieCordonnes="getRegiechecked"
              :editable="cantUpdate($options.name)"
            />
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Liste-des-documents',
  data() {
    return {
      options: [
        { value: 'KBIS', text: 'KBIS' },
        { value: 'Quitus_Fiscal', text: 'Quitus Fiscal' },
        { value: 'Attestation_URSAAF', text: 'Attestation URSAAF' }
      ],
      listTab: [{ name: 'Coordonnées' }, { name: 'Fichiers' }],
      tabsActive: 'Coordonnées',
      filterRegie: { id: null, name: 'tous' },
      page: 1,
      perPage: 20,
      fields: [
        {
          key: 'detail',
          label: '',
          stickyColumn: true,
          thClass: 'check-th-style',
          tdClass: 'check-td-style'
        },
        { key: 'regie_name', label: 'Nom' },
        { key: 'email', label: 'E-mail' },
        { key: 'adress', label: 'Adresse' },
        { key: 'code_postal', label: 'Code postal' },

        { key: 'phone', label: 'Phone' },
        { key: 'ville', label: 'Ville' }
      ],
      newRegieState: false,
      selectedRegie: null,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ]
    };
  },
  methods: {
    ...mapActions([
      'getAllRegieDocFiltred',
      'fetchAllRegieForAdmin',
      'DownloadingRegieDoc',
      'deleteRegieFileInSuperAdmin',
      'editRegieInfoForAdmin',
      'fetchRegieWithid'
    ]),
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.selectedRegie.details = false;
    },
    tabsChange(event) {
      this.tabsActive = event;
    },
    rowClass(item, type) {
      if (item && item.details && item.details == true) {
        return 'ligneClicked';
      } else return 'ligneNormale';
    },
    handleChange(payload) {
      this.page = 1;
      if (payload) {
        this[payload.champName] = payload.value;
      }
      this.getAllRegieDocFiltred({
        id: this.filterRegie,
        page: this.paginate,
        per_page: this.perPage
      });
    },
    changePerPage() {
      this.page = 1;
      this.getAllRegieDocFiltred({
        id: this.filterRegie,
        page: this.page,
        per_page: this.perPage
      });
    },
    pagination(paginate) {
      this.page = paginate;
      this.getAllRegieDocFiltred({
        id: this.filterRegie,
        page: this.page,
        per_page: this.perPage
      });
    },
    handleChangeDetails(item) {
      item.details = true;
      this.$refs['regieInfo'].show();
      this.selectedRegie = item;
      this.fetchRegieWithid({ id: this.selectedRegie.id });
    }
  },
  computed: {
    ...mapGetters([
      'getRegieDocFiltred',
      'getAllRegie',
      'getTotalRowDocRegie',
      'getChargementDocRegie',
      'getLoadingModelRegie',
      'getRegiechecked',
      'cantUpdate',
      'cantDelete',
      'cantExport'
    ]),
    computedgetAllRegie() {
      return [{ id: null, name: 'tous' }, ...this.getAllRegie];
    },
    getAllFiles() {
      if (this.getRegiechecked) {
        return (
          this.getRegiechecked.files.Attestation_URSAAF.length +
          this.getRegiechecked.files.KBIS.length +
          this.getRegiechecked.files.Quitus_Fiscal.length
        );
      } else {
        return 0;
      }
    }
  },
  async mounted() {
    this.fetchAllRegieForAdmin();
    await this.getAllRegieDocFiltred({
      id: this.filterRegie,
      page: this.page,
      per_page: this.perPage
    });
  },
  filters: {
    FilterValue: value => {
      if (value != null && value != '') {
        return value;
      } else {
        return '-';
      }
    }
  },
  components: {
    SelectComponent: () => import('../SelectComponent'),
    tabs: () => import('../component/tabs.vue'),
    fileComponent: () => import('./fileTabs.vue'),
    dataComponent: () => import('./cordonneTabs.vue')
  }
};
</script>

<style lang="scss" scoped>
.document-content {
  padding: 10px !important;
  margin: 0px !important;
  height: calc(100vh - 111px);
  display: block;
  .header {
    width: 100%;
  }
  .tableDocRegie {
    max-height: calc(100vh - 210px) !important;
    margin-bottom: 0px;
  }
  .body-box-rapport {
    height: calc(100vh - 175px);
  }
}
.checkbox {
  width: 20px;
  height: 20px;
  border: 1px solid #858585;
  color: #858585;
  border-radius: 17px;
  font-size: 11px;
  align-items: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .checkbox_icon {
    display: none;
  }
}
.checked {
  background: rgb(77, 75, 172);
  color: #fff;
  .checkbox_icon {
    display: inline-block;
  }
}
</style>
<style lang="scss">
.tableDocRegie {
  td,
  th {
    min-width: 197px !important;
  }
  td {
    padding: 5px;
  }
  .check-td-style,
  .check-th-style {
    width: 50px !important;
    min-width: 50px !important;
  }
}
</style>
